import React, { useState, useEffect } from 'react';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import {Table, Form } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { Link } from 'react-router-dom';


const ItemStockDetails: React.FC = (props: any) => {

    const query = new URLSearchParams(props.location.search);
    const idItems: number = Number(query.get('id_items'));
    const idProjects: number = Number(query.get('id_projects'));
    const [item, setItem] = useState<null>(null);
    const [stockIn, setStockIn] = useState<null>([]);
    const [stockOut, setStockOut] = useState<null>([]);

    const [formData, setFormData] = useState({
        id_projects: idProjects,
        id_items: idItems,
        from_date: '',
        to_date: '',
    });

    const getStockDetails = async () => {
        try {
            const queryParams = new URLSearchParams({
                id_projects: formData.id_projects,
                id_items: formData.id_items,
                from_date: formData.from_date,
                to_date: formData.to_date,
            });

            const url = process.env.REACT_APP_API_URL + 'stock-details?' + queryParams.toString();

            const response = await fetch(url, getRequestOptions());
            const data = await response.json();
            setItem(data.data.item);
            setStockIn(data.data.stockIn);
            setStockOut(data.data.stockOut);
           
        } catch (error) {
            console.error('catch the hoop', error);
        }
    };
    
    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }


    useEffect(() => {
        const fetchData = async () => {
            await getStockDetails();
        };
        fetchData();
        // getStockDetails();
        // eslint-disable-next-line
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = () => {
        getStockDetails();
    }

    const totalStockIn = stockIn.reduce((total, item) => total + Number(item.quantity), 0);
    const totalStockOut = stockOut.reduce((total, item) => total + Number(item.quantity), 0);
    const currentStock = totalStockIn - totalStockOut;

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Stock (In/Out) Details for {item?.itemName}
                        </span>
                    </div>
                    <div className="card-toolbar">
                        <div className='d-flex justify-content-end'>
                            <Link className="btn btn-info btn-sm" to={`/stock-status`}>
                                <KTSVG
                                    path='/media/svg/icons/Media/Back.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Back to Inventory Stock Status List
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='card-body'>
                    <div className="row">
                        <div className="col mb-2">
                            <p className='mb-0'><strong>Item Name:</strong> {item?.itemName}</p>
                            <p className='mb-0'><strong>Item Code:</strong> {item?.itemCode}</p>
                            <p className='mb-0'><strong>Item Category:</strong> {item?.item_category.categoryName}</p>
                            <p className='mb-0'><strong>Unit:</strong> {item?.unit?.unitName}</p>
                            <p className='mb-0'><strong>Stock:</strong> {numberFormat(currentStock)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-2'>
                            <Form.Group className="mb-3" controlId="from_date">
                                <Form.Label>From Date<span className='required'></span></Form.Label>
                                <Form.Control type="date" placeholder="From Date" name='from_date' onChange={handleChange}  className='form-control-sm'/>
                            </Form.Group>
                        </div>
                        <div className='col-md-2'>
                            <Form.Group className="mb-3" controlId="to_date">
                                <Form.Label>To Date<span className='required'></span></Form.Label>
                                <Form.Control type="date" placeholder="To Date" name='to_date' onChange={handleChange} className='form-control-sm'/>
                            </Form.Group>
                        </div>
                        <div className='col-md-2'>
                            <div className="form-control-sm ps-0 mt-7">
                                <button className='btn btn-sm btn-primary w-100 p-2' onClick={handleViewReport}>
                                    <span>GO</span>
                                </button>
                            </div>                                
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Purchase Ref. No/Manufacturing Ref. No </th>
                                        <th className='text-center'>Rate </th>
                                        <th className='text-end'>Quantity </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stockIn.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>Purchase</td>
                                            <td>{item.referenceNo}</td>
                                            <td className='text-end'>{item.rate}</td>
                                            <td className='text-end'>{item.quantity}</td>
                                        </tr>
                                    ))}
                                    {stockOut.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>Manufacturing</td>
                                            <td>{item.referenceNo}</td>
                                            <td className='text-end'>{item.rate}</td>
                                            <td className='text-end'>{item.quantity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={4} className='text-end'><strong>Total Stock In</strong></th>
                                        <th className='text-end'><strong>{numberFormat(totalStockIn)}</strong></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4} className='text-end'><strong>Total Stock Out</strong></th>
                                        <th className='text-end'><strong>{numberFormat(totalStockOut)}</strong></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4} className='text-end'><strong>Current Stock</strong></th>
                                        <th className='text-end'><strong>{numberFormat(currentStock)}</strong></th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                    

                    <div className='row'>
                        <div className='col text-center'>

                            <Link className="btn btn-info btn-sm" to={`/stock-status`}>
                                <KTSVG
                                    path='/media/svg/icons/Media/Back.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Back to Inventory Stock Status List
                            </Link>

                        </div>
                    </div>
                </div>
                
            </div>
            
        </>
    )
}

export default ItemStockDetails
