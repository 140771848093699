/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
// import { Table } from 'react-bootstrap-v5';
import DataTable from '../components/DataTable'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const ItemGlobalRating = () => {

    // const [entity, setEntity] = useState('');
    const [newData, setNewData] = useState({});
    const [items, setItems] = useState([]);
    const [item, setItem] = useState({ label: 'Select Item', value: '' });
    const [itemCategories, setItemCategories] = useState([]);
    const [itemCategory, setItemCategory] = useState({ label: 'Select Item Category', value: '' });
    const [itemRate, setItemRate] = useState(null);

    useEffect(() => {
        getItemCategories();
    }, [])

    const getItemCategories = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'allItemCategories',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let itemObj = resp.map((item: any) => {
                    item.label = item.category_name;
                    item.value = item.id_item_categories;
                    return item
                })
                setItemCategories(itemObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }
    const getItems = (idCategories: number) => {
        fetch(
            process.env.REACT_APP_API_URL + 'allItemsGlobalRating?idCategories=' + idCategories,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let itemObj = resp.map((item: any) => {
                    item.label = item.item_name + ' - ID # ' + item.id_items;
                    item.value = item.id_items;
                    return item
                })
                setItems(itemObj);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const handleItemCategoryChange = (itemCategory: any) => {
        console.log(itemCategory)
        setItemCategory(itemCategory);
        getItems(itemCategory.value);
    }

    const handleItemChange = (item: any) => {
        setItem(item);
        setItemRate(item.rate);
    }

    const submitMergeRequest = async (event: any) => {
        event.preventDefault()
        // setSubmitDisable(true);
        // setLoading(true);
        const confirm = await Swal.fire({
            title: 'Are you sure to set this rate??',
            text: "You can't revert theis action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ec008c',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Set it!',
        })
        if (confirm.value) {
            const postData = {
                rate: itemRate,
                idItems: item.value,
            }
            const requestOptions = postRequestOptions(postData)
            try {
                let response = await fetch(
                    `${process.env.REACT_APP_API_URL}item/set-global-rate`,
                    requestOptions
                )
                let respData = await response.json()
                if (respData.success === true) {
                    const successMsg = respData.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })

                    setNewData({ id: Date.now() });

                } else {
                    const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
                // setSubmitDisable(false);
                // setLoading(false);
            } catch (error) {
                console.log(error, 'catch the hoop')
                // setSubmitDisable(false);
                // setLoading(false);
            }
        }
    }

    const handleItemRateChange = (e: any) => {
        setItemRate(e.target.value);
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'itemName', title: 'Item Name', style: { textAlign: 'left' } },
        { value: 'itemCode', title: 'Item Code', style: { textAlign: 'left' } },
        { value: 'categoryName', title: 'Item Category', style: { textAlign: 'left' } },
        { value: 'rate', title: 'Rate', style: { textAlign: 'center' } },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        { value: 'createdAt', title: 'Created At', style: { textAlign: 'left' } },


        // {
        //     value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
        //         return (
        //             <>
        //                 {row.status === 'Merged' &&
        //                     <button className="btn btn-light-danger btn-sm p-2" onClick={() => rollBackMerge(row.id)}>
        //                         <KTSVG
        //                             path='/media/svg/icons/Navigation/Left 3.svg'
        //                             className='svg-icon-5 svg-icon'
        //                         />		Roll Back
        //                     </button>
        //                 }
        //             </>
        //         )
        //     })
        // }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        itemName: {
            filterType: 'like',
            filterValue: '',
        },
        itemCode: {
            filterType: 'like',
            filterValue: '',
        },
        categoryName: {
            filterType: 'like',
            filterValue: '',
        },
        rate: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
        createdAt: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'itemGlobalRate/dt'

    // const rollBackMerge = async (id: number) => {


    //     const confirm = await Swal.fire({
    //         title: 'Are you sure to Roll Back?',
    //         text: "You can't revert theis action",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#ec008c',
    //         cancelButtonText: 'Cancel',
    //         confirmButtonText: 'Yes, Roll Back!',
    //     })
    //     if (confirm.value) {

    //         const requestOptions = postRequestOptions()
    //         try {
    //             let response = await fetch(
    //                 `${process.env.REACT_APP_API_URL}merge-entity-rollback/${id}`,
    //                 requestOptions
    //             )
    //             let respData = await response.json()
    //             if (respData.success === true) {
    //                 const successMsg = respData.successMessage
    //                 Swal.fire({
    //                     icon: 'success',
    //                     title: 'Success',
    //                     text: successMsg,
    //                 })

    //                 setNewData({ id: Date.now() });
    //             } else {
    //                 const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Error',
    //                     html: errorsMessage,
    //                 })
    //             }
    //             // setSubmitDisable(false);
    //             // setLoading(false);
    //         } catch (error) {
    //             console.log(error, 'catch the hoop')
    //             // setSubmitDisable(false);
    //             // setLoading(false);
    //         }
    //     }

    // };

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <KTSVG
                        path='/media/icons/duotune/coding/cod001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Item Global Rating
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <div className="card-body">

                    <Tabs
                        defaultActiveKey="new-rate-setting"
                        id="uncontrolled-tab-example"
                        className="mb-3 ml-3 mr-3"
                    >
                        <Tab eventKey="new-rate-setting" title="Set New Rate">
                            {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
                            <form onSubmit={submitMergeRequest}>

                                {/* <div className="form-group row">
                            <label htmlFor="idItems" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Entity <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <select className="form-select form-select-sm" id="entity" name="entity" onChange={handleEntityChange} value={entity}>
                                    <option value="">Select Entity</option>
                                    <option value={'Item'}>Item</option>
                                    <option value={'Client'}>Client</option>
                                    <option value={'Product'}>Product</option>
                                </select>
                            </div>
                        </div> */}


                                <div className="form-group row">
                                    <label htmlFor="idItems" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Item Category<span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <Select
                                            value={itemCategory}
                                            onChange={handleItemCategoryChange}
                                            options={itemCategories}
                                            menuPosition="fixed"
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="idItems" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Select Item <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <Select
                                            value={item}
                                            onChange={handleItemChange}
                                            options={items}
                                            menuPosition="fixed"
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="idLeads" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>New Rate  <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type='number' step='any' name='globalRate' value={itemRate} className='form-control' onChange={handleItemRateChange}></input>
                                    </div>
                                </div>



                                <div className="row mt-4">
                                    <div className="col-12 text-center">
                                        <input type="submit" className="btn btn-sm btn-success me-2" />
                                        <Link to='/dashboard' type="reset" className="btn btn-sm btn-secondary">Cancel</Link>
                                    </div>
                                </div>


                            </form>
                        </Tab>
                        <Tab eventKey="rate-list" title="Rate List History">
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div >

    )
}

export default ItemGlobalRating
