import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import 'react-loading-skeleton/dist/skeleton.css'
import Select from 'react-select'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { Table } from 'react-bootstrap-v5';


function AssignSrCategory() {

    const [sr, setSr] = useState();
    const [salesRepresentitives, setSalesRepresentatives] = useState([]);
    const [categories, setProjects] = useState([]);

    useEffect(() => {
        getAllSalesRepresentative()
    }, [])

    const getAllSalesRepresentative = () => {

        fetch(
            process.env.REACT_APP_API_URL + 'getAllSr',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                let users = resp.map((item: any) => {
                    item.label = item.first_name + ' ' + item.last_name + ' (' + item.assigned_projects + ')';
                    item.value = item.id;
                    return item
                })
                setSalesRepresentatives(users);

            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })


    }

    const handleItemChange = (sr: any) => {

        setSr(sr)

        fetch(
            process.env.REACT_APP_API_URL + 'allSrCategory',
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setProjects(resp)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })

    }

    const handleSrCategoryChange = (e: any, k: number) => {

        const postData = {
            idSrCategories: e.target.value,
            idUsers: sr.id,
        };

        const requestOptions = postRequestOptions(postData);

        fetch(
            process.env.REACT_APP_API_URL + 'set_sr_categories', requestOptions
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                // setSr(sr)

                setSr(prevState => ({
                    ...prevState,
                    id_sr_categories: parseInt(e.target.value)
                }));

                Swal.fire({
                    icon: 'success',
                    title: "Done",
                    text: resp.message,
                })

                getAllSalesRepresentative()
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })

    }

    return (
        <>

            <div className='form-group row'>
                <label htmlFor='role' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
                    Select SR
                </label>
                <div className='col-lg-5'>
                    <Select
                        onChange={(selectedOption) => handleItemChange(selectedOption)}
                        options={salesRepresentitives}
                        className='sm'
                        menuPosition="fixed"
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                height: '32px',
                                minHeight: '32px',
                            }),
                            container: (provided, state) => ({
                                ...provided,
                                height: '32px',
                            }),
                        }}
                    />
                </div>

            </div>

            {categories.length > 0 &&
                <div className='form-group row'>
                    <div className='col-3'></div>
                    <div className='col-5'>
                        <Table striped bordered hover size='sm'>
                            <thead>
                                <tr>
                                    <th className='text-start'>SR Category</th>
                                    <th className='text-center'>Assign</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories
                                    .filter(v => v.id_sr_categories !== '-1')
                                    .map((v, k) => (
                                        <tr key={k}>
                                            <td>{v.sr_category_name}</td>
                                            <td className='text-center'>
                                                <input type="radio" value={v.id_sr_categories} onChange={(e) => handleSrCategoryChange(e, k)} checked={v.id_sr_categories === sr.id_sr_categories ? true : false} />
                                            </td>
                                        </tr>
                                    ))}
                                {categories.length === 0 &&
                                    <tr>
                                        <td colSpan={2}>No SR Category Found</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            }

            <div className='form-group row'>
                <div className='col-2'></div>
                <div className='col-8'>
                    {/* <pre>{JSON.stringify(sr, null, 2)}</pre> */}
                    <Table striped bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th className='text-start'>SR List</th>
                                <th className='text-center'>Assigned SR Category</th>
                                <th className='text-center'>Assigned Projects</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesRepresentitives.map((v, k) => (
                                <tr key={k}>
                                    <td>{v.first_name + ' ' + v.last_name}</td>
                                    <td>{v.sr_category_name}</td>
                                    <td>{v.assigned_projects}</td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div>
            </div>


            {/* {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            } */}

        </>
    )
}

export default AssignSrCategory
