/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
// import { Col, Row } from 'react-bootstrap-v5';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
// import showLoader from "../helpers/ShowLoader"
// import Badge from 'react-bootstrap/Badge';
// import { AppContext } from '../context/AppContext'
// import axios from 'axios'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { Table } from 'react-bootstrap-v5';


interface AlternateNameCreateProps {
	show: boolean;
	handleClose: any;
	productGroup: any;
}

interface InitialState {
	idProductGroups: number;
	alternateName: string;
}

interface AlternateName {
	id_product_group_alternate_names: number;
	alternate_name: string;
}

const AlternateNameUploader: React.FC<AlternateNameCreateProps> = ({ show, handleClose, productGroup }) => {

	const initialState: InitialState = {
		idProductGroups: 0,
		alternateName: '',
	};

	const [item, setItem] = useState(initialState);
	const [alternateNames, setAlternateNames] = useState<AlternateName[]>([]);

	const getPorductAlternateNames = (idProductGroups: number) => {
		fetch(
			process.env.REACT_APP_API_URL + 'productGroupAlternateName/' + idProductGroups,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {


				setAlternateNames(resp);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	useEffect(() => {
		console.log(productGroup);
		setItem({ idProductGroups: productGroup?.idItemCategories, alternateName: '' });
		getPorductAlternateNames(productGroup?.idItemCategories)
	}, [productGroup])

	const handleChange = (e: any) => {
		setItem({ ...item, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault()
		// setSubmitDisable(true);
		// setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to store this Alternate Name?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Sure!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions(item)
			try {
				let url = `${process.env.REACT_APP_API_URL}productGroupAlternateName/store`
				let response = await fetch(
					url,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
					getPorductAlternateNames(productGroup.idItemCategories)
					// handleClose(respData.success)
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				// setSubmitDisable(false);
				// setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				// setSubmitDisable(false);
			}
		}
	}

	const deleteAlternateName = async (idAlternateNames: number) => {

		// setSubmitDisable(true);
		// setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to Delete this Alternate Name?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Delete it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions()//postRequestOptions(categories)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}productGroupAlternateNameDelete/${idAlternateNames}`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {

					getPorductAlternateNames(productGroup.idItemCategories)
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
				} else {
					// const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: 'Could not delete product. It has dependant data.',
					})
				}
				// setSubmitDisable(false);
				// setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				// setSubmitDisable(false);
				// setLoading(false);
			}
		}
	}


	return (
		<Modal show={show} onHide={() => handleClose()} size="lg">

			<Modal.Header className="py-2" closeButton>
				<Modal.Title>Alternate Names - {productGroup?.itemName + ' (' + productGroup?.itemCode + ')'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* <pre>{JSON.stringify(alternateNames, null, 2)}</pre>
				<pre>{JSON.stringify(alternateNames.length, null, 2)}</pre> */}
				<div className="form-group row">
					<label htmlFor="alternateName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>New Alternate Name <span className="required text-danger"></span></label>
					<div className="col-lg-5">
						<input type="text" name="alternateName" id="alternateName" onChange={handleChange} className="form-control form-control-sm" />
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="alternateName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}></label>
					<div className="col-lg-5">
						<button className="btn btn-sm btn-primary me-2 mt-2" onClick={handleSubmit}>
							Save
						</button>
					</div>
				</div>

				<div className="form-group row mt-6">

					<div className="col">
						<Table size='sm' bordered hover>
							<thead>
								<tr>
									<th style={{ width: '90%' }}>Alternate Name</th>
									<th className='text-center' style={{ width: '10%' }}>Action</th>
								</tr>
							</thead>
							<tbody>
								{alternateNames.map((v) => (
									<tr key={v.id_product_alternate_names}>
										<td>{v.alternate_name}</td>
										<td className='text-center'>
											<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteAlternateName(v.id_product_group_alternate_names)} title='Delete'>
												<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' title="Delete" />
											</button>
										</td>
									</tr>
								))}

								{alternateNames.length === 0 &&
									<tr key=''>
										<td colSpan={2}>No Alternate Name Found</td>
									</tr>
								}

							</tbody>
						</Table>
					</div>
				</div>


			</Modal.Body>
			<Modal.Footer className='py-1'>
				<Button variant='secondary' size="sm" onClick={() => handleClose()}>
					Close
				</Button>
			</Modal.Footer>

		</Modal >
	)
}

export default AlternateNameUploader
