import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';

interface PurchaseData {
  
}

const ItemWisePurchaseReport: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState<PurchaseData | null>(null);  // Initialize as null
    const [formData, setFormData] = useState({
        idProjects: '',
        fromDate: '',
        toDate: '',
    });

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, []);

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => resp.json())
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setProjects(projectObj);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error);
            });
    };

    const handleProjectChange = (project: any) => {
        setProject(project);
        setFormData({ ...formData, idProjects: project ? project.value : '' });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleViewReport = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + 'reports/item-wise-purchase',
                postRequestOptions(formData)
            );
            const data = await response.json();
            if (data.success) {
                setPurchaseData(data.data);
            } else {
                setPurchaseData(null);
                for (const i in data.errors) {
                    toast(<strong>{data.errors[i].join(' ')}</strong>, {
                        type: 'error',
                        position: 'bottom-right',
                        autoClose: 4000,
                    });
                }
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        } finally {
            setLoading(false);
        }
    };

    const queryParams = new URLSearchParams(formData);

    
    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <button className='btn btn-sm btn-primary p-3' onClick={handleViewReport} disabled={loading}>
                                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                                        <span><i className='fas fa-eye'></i> View Report</span>
                                    </button>
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}item_wise_purchase_report_download?${queryParams.toString()}`}><i className='fas fa-download'></i> Excel Download</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    {purchaseData ? (
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th className='text-center'>Qty</th>
                                    <th>Unit</th>
                                    <th className='text-center'>Average Rate</th>
                                    <th className='text-center'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr key={`${index}`}>
                                            <td>{item.itemName}</td>
                                            <td className='text-end'>{numberFormat(item.totalQuantity)}</td>
                                            <td>{item.unitName}</td>
                                            <td className='text-end'>{numberFormat(item.averageRate)}</td>
                                            <td className='text-end'>{numberFormat(item.totalQuantity*item.averageRate)}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                                <tr key={`total`}>
                                    <td className='text-end'><strong>Total:</strong></td>
                                    <td className='text-end'><strong>{numberFormat(purchaseData.reduce((acc, item) => acc + Number(item.totalQuantity), 0))}</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'><strong>{numberFormat(purchaseData.reduce((acc, item) => acc + (Number(item.totalQuantity) * Number(item.averageRate)), 0))}</strong></td>
                                </tr>
                            </tbody>
                        </Table>

                    ) : (
                        <div>No data available</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ItemWisePurchaseReport;