import React from 'react';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { getRequestOptions } from "../helpers/Fetchwrapper";
import { Card } from 'react-bootstrap-v5';
import 'react-toastify/dist/ReactToastify.css';
import { numberFormat } from '../helpers/GlobalHelper';
import DataTable from '../components/DataTable';
import { KTSVG } from '../../_metronic/helpers'
import { Link } from 'react-router-dom';

const DetailSalesItemSearch: React.FC = () => {
    const [formData, setFormData] = useState({
        idProjects: '',
        fromDate: '',
        toDate: '',
    });

    const [filterColumns, setFilterColumns] = useState({
        idProjects: '',
        fromDate: '',
        toDate: '',
        order: 'DESC',
        sortedColumn: 'id',
    });

    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState({ label: 'Select Project', value: '' });

    useEffect(() => {
        getProjects();
    }, []);

    const jwt = () => {
		const myToken = localStorage.getItem('MyToken');
		return (myToken != null) ? JSON.parse(myToken) : null;
	}

    const getProjects = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'lead/projects',
            getRequestOptions()
        )
            .then((resp) => resp.json())
            .then((resp) => {
                let projectObj = resp.data.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setProjects(projectObj);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error);
            });
    };

    const handleProjectChange = (project: any) => {
        setProject(project);
        setFormData({ ...formData, idProjects: project ? project.value : '' });
        setFilterColumns({ ...filterColumns, idProjects: project ? project.value : '' });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setFilterColumns({ ...filterColumns, [name]: value });
    };

    const columns = [
        { value: 'id', title: 'ID', style: { textAlign: 'center' } },
        { value: 'projectName', title: 'Project', style: { textAlign: 'left' } },
        { value: 'invoiceId', title: 'Invoice ID', style: { textAlign: 'left' } },
        { value: 'salesOrderId', title: 'Sales Order ID', style: { textAlign: 'left' } },
        { value: 'client', title: 'Client', style: { textAlign: 'left' } },
        { value: 'invoiceDate', title: 'Invoice Date', style: { textAlign: 'left' } },
        { value: 'creditDays', title: 'Credit Days', style: { textAlign: 'left' } },
        { value: 'productName', title: 'Product', style: { textAlign: 'left' } },
        { value: 'packing', title: 'Packing', style: { textAlign: 'left' } },
        {
            value: 'orderedQuantity', title: 'Ordered Quantity', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.orderedQuantity)}
                    </>
                )
            },
        },
        {
            value: 'deliveredQuantity', title: 'Delivered Quantity', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.deliveredQuantity)}
                    </>
                )
            },
        },
        {
            value: 'unitRate', title: 'Rate', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.unitRate)}
                    </>
                )
            },
        },
        {
            value: 'deliveredAmount', title: 'Amount', style: { textAlign: 'center' },
            hasHtmlComponent: true,
            htmlComponent: (row: any) => {
                return (
                    <>
                        {numberFormat(row.deliveredAmount)}
                    </>
                )
            },
        },
        { value: 'createdBy', title: 'Created By', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'left', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row: any) => {
                return (
                    <>
                        <Link className="btn btn-light-primary btn-sm px-2 py-1" to={`sales-invoice/${row.idSalesInvoice}`}>
                            <KTSVG
                                path='/media/svg/icons/Layout/Layout-grid.svg'
                                className='svg-icon-5 svg-icon'
                            />		Details
                        </Link>
                       
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        projectName: {
            filterType: 'like',
            filterValue: '',
        },
        invoiceId: {
            filterType: 'like',
            filterValue: '',
        },
        salesOrderId: {
            filterType: 'like',
            filterValue: '',
        },
        client: {
            filterType: 'like',
            filterValue: '',
        },
        invoiceDate: {
            filterType: 'like',
            filterValue: '',
        },
        creditDays: {
            filterType: 'like',
            filterValue: '',
        },
        productName: {
            filterType: 'like',
            filterValue: '',
        },
        packing: {
            filterType: 'like',
            filterValue: '',
        },
        orderedQuantity: {
            filterType: 'like',
            filterValue: '',
        },
        deliveredQuantity: {
            filterType: 'like',
            filterValue: '',
        },
        unitRate: {
            filterType: 'like',
            filterValue: '',
        },
        deliveredAmount: {
            filterType: 'like',
            filterValue: '',
        },
        createdBy: {
            filterType: 'like',
            filterValue: '',
        },
    }

    const url = process.env.REACT_APP_API_URL + 'reports/sales-item/search'

    const additionalPostData = {
		idProjects: project.value,
        fromDate: formData.fromDate,
		toDate: formData.toDate,
	};

    
    const getFilterParams =(data:any)=>{
       
        // Create an updated filterColumns object
        const updatedColumns: Record<string, any> = {};
    
        // Iterate through each column in the data.columns object
        Object.keys(data.columns).forEach((columnKey: string) => {
            const column = data.columns[columnKey];
            updatedColumns[columnKey] = column.filterValue || ''; // Default to empty string if filterValue is undefined
        });

        setFilterColumns({
            ...filterColumns, // Include the existing column filters
            ...updatedColumns, // Include the new column filters
            order: data.order || 'DESC',
            sortedColumn: data.sorted_column || 'id',
            globalSearch: data.globalSearch || '',
            token: data.token || '',
        });

    }

    // Construct query parameters
    const queryParams = new URLSearchParams(Object.entries(filterColumns));

        

    return (
        <>
            <Card className='mb-4'>
                <Card.Body>
                    <Card.Text>
                        <div className='row'>
                            <div className='col-md-3'>
                                <Form.Group className="mb-3" controlId="project">
                                    <Form.Label>Project<span className='required'></span></Form.Label>
                                    <Select
                                        value={project}
                                        onChange={handleProjectChange}
                                        options={projects}
                                        menuPosition="fixed"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>From Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="From Date" name='fromDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-2'>
                                <Form.Group className="mb-3">
                                    <Form.Label>To Date<span className='required'></span></Form.Label>
                                    <Form.Control type="date" placeholder="To Date" name='toDate' onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-control-sm ps-0 mt-7">
                                    <a className="btn btn-sm btn-primary p-3 ms-3" href={`${process.env.REACT_APP_BACKEND}detail_sales_item_search_report_download?${queryParams.toString()}&token=`+jwt()}><i className='fas fa-download'></i> Excel Download</a>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>

            <div className='row'>
                <div className='col-12'>
                    <DataTable url={url} columns={columns} filters={filters} additionalPostData={additionalPostData} onValueChange={(state:any) => {
                        getFilterParams(state);
                    }}/>
                </div>
            </div>
        </>
    );
};

export default DetailSalesItemSearch;